/// <reference types="./types" />
/// <reference types="./settings" />

(() => {
  const IS_SHOP_STAGING = window.location.href.includes('tienda-qa.priceshoes.digital');
  const SHOP_BASE_API_URL = IS_SHOP_STAGING
    ? 'https://api-qa.priceshoes.digital'
    : 'https://api.priceshoes.digital';
  const SHOP_BASE_URL = IS_SHOP_STAGING
    ? 'https://tienda-qa.priceshoes.digital'
    : 'https://www.priceshoes.com';
  const SHOP_CART_URL = `${SHOP_BASE_URL}/cart`;

  const SHOP_CART_ID_KEY = 'ps-cart-id';
  const SHOP_CART_TOTALS_AUTH = 'ps-cart-totals-auth';

  const isDebugMode = () => {
    return localStorage.getItem('terrific_debug') === 'true';
  };

  const isPicInPicEnabled = () => {
    return localStorage.getItem('pic-in-pic-status') === 'true';
  };

  const consoleProxy = (
    message: any,
    level: 'log' | 'warn' | 'info' | 'error' | 'debug' = 'log'
  ) => {
    if (level === 'error') {
      console.error(message, 'error');
      return;
    } else if (isDebugMode()) {
      console[level](message);
    }
  };

  consoleProxy('Terrific Embed SDK: Loaded Price Shoes config');

  window.terrificLiveSettings = {
    baseApiUrl: IS_SHOP_STAGING ? 'https://staging.terrific.live' : 'https://terrific.live',
    registerTerrificWebComponent: true,
    goToCheckoutConfig: {
      cartProductTransform: {
        variantSku: 'sku',
        quantity: 'qty',
      },
      clearCurrentCart: false,
      goToCheckoutHandler: async (data: {cartProducts: TransformedCartProduct[]}) => {
        // this is based on Magento 2.4 rest api
        // via guest cart for holding the terrific cart products
        // and then via customer cart for adding the products to the customer cart
        // and then the user is redirected to the checkout page
        if (!data.cartProducts.length) return;

        const headers = new Headers({
          'content-type': 'application/json',
        });
        const token = await fetchApiRequest(`${SHOP_BASE_URL}/api/auth/revalidate`, {
          method: 'POST',
          credentials: 'include',
          headers: {'content-type': 'application/json'},
        })
          .then((res) => res.json() as Promise<string>)
          .catch((e) => {
            consoleProxy(`Error during revalidate: ${e}`, 'error');
            return null;
          });

        if (token) {
          headers.append('Authorization', `Bearer ${token}`);
          // else the token is in the cookie as httpOnly cookie
          // and we can't access it from the frontend
          // in that case we are not needed to add the token to the headers
          // and it will be added automatically
        } else {
          consoleProxy(
            'No token found, the user is not logged in, cannot create customer cart',
            'error'
          );
          return;
        }

        const toSkipCustomerCart =
          !!window.terrificLiveSettings?.goToCheckoutConfig?.clearCurrentCart;

        let currentUserCartId = !toSkipCustomerCart && localStorage.getItem(SHOP_CART_ID_KEY);

        const quote_id = String(currentUserCartId || (await createNewCart()));

        const {cartProducts} = data;
        await addProductsToGuestCart(cartProducts);
        if (!localStorage.getItem(SHOP_CART_ID_KEY)) {
          try {
            const stringifyQuoteId = JSON.stringify(quote_id.replace(/"/g, ''));
            localStorage.setItem(SHOP_CART_ID_KEY, stringifyQuoteId);
          } catch (_error) {
            localStorage.setItem(SHOP_CART_ID_KEY, quote_id);
          }
          try {
            const stringifyTotalsAuth = JSON.stringify(token.replace(/"/g, ''));
            localStorage.setItem(SHOP_CART_TOTALS_AUTH, stringifyTotalsAuth);
          } catch (_error) {
            localStorage.setItem(SHOP_CART_TOTALS_AUTH, token);
          }
        }

        setTimeout(() => {
          if (isPicInPicEnabled()) {
            window.open(SHOP_CART_URL);
          } else {
            window.location.href = SHOP_CART_URL;
          }
        }, 1000);

        async function createNewCart(): Promise<string> {
          try {
            currentUserCartId =
              !toSkipCustomerCart &&
              (
                await fetchApiRequest(`${SHOP_BASE_API_URL}/v1/carts/mine`, {
                  method: 'get',
                  headers,
                }).then((res) => res.json() as Promise<{id: string}>)
              ).id;
          } catch (_error) {
            //ignore
          }
          return (
            currentUserCartId ||
            (await fetchApiRequest(`${SHOP_BASE_API_URL}/v1/carts`, {
              method: 'POST',
              headers,
            }).then((res) => res.json() as Promise<string>))
          );
        }

        async function addProductsToGuestCart(cartProducts: TransformedCartProduct[]) {
          const parsedQuoteId = quote_id.replace(/"/g, '');

          const tasks = cartProducts.map((cartProduct) => {
            const productToAdd = {
              ...cartProduct,
              quote_id: parsedQuoteId.replace(/"/g, ''),
            };

            return fetchApiRequest(`${SHOP_BASE_API_URL}/v1/carts/${parsedQuoteId}/items`, {
              method: 'POST',
              headers,
              body: JSON.stringify({
                cartItem: productToAdd,
              }),
            });
          });

          try {
            await Promise.all(tasks);
          } catch (error) {
            consoleProxy(`Error adding products to cart: ${error}`, 'error');
            throw error;
          }
        }

        async function fetchApiRequest(url: string, options: RequestInit) {
          if ((options.method === 'POST' || options.method === 'PUT') && !options.body) {
            options.body = '{}';
          }
          options = {
            ...options,
            headers: options.headers,
            mode: 'cors', // this is needed for the preflight request
            redirect: 'follow',
          };
          return fetch(url, options)
            .then((res) => {
              if (res.status >= 400) {
                consoleProxy(`Error during fetchApiRequest: ${{res, url, options}}`, 'error');
              }
              return res;
            })
            .catch((e) => {
              consoleProxy(`Error during fetchApiRequest: ${{e, url, options}}`, 'error');
              throw e;
            });
        }
      },
    },
  };
})();
